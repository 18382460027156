import cx from "classnames";
import infoIcon from "../assets/images/info_button.png";
import closeIcon from "../assets/images/close_button.png";
import React, {useState} from "react";

export const PlayerOverlay = ({video, ...props}) => {
    const [overlayDisplayed, setOverlayDisplayed] = useState(false)

    return (<>
        <img className={cx("info-button", {hidden: overlayDisplayed})}
             alt={"info button"}
             src={infoIcon}
             onClick={() => setOverlayDisplayed(true)}
        />

        <div className={cx("video-overlay-wrapper", {hidden: !overlayDisplayed})}>
            <div className={"video-overlay"}>
                <img className={"video-overlay-logo"}
                     src={video.avatar_url + "?w=50"}
                     alt={"video creator logo"}/>
                <div className={"video-overlay-info"}>
                    <div className={"overlay-title"}>{video.title}</div>
                    <div className={"overlay-desc"}>{video.description}</div>
                </div>
            </div>
        </div>

        <img className={cx("close-button", {hidden: !overlayDisplayed})}
             alt={"close button"}
             src={closeIcon}
             onClick={() => setOverlayDisplayed(false)}
        />
    </>)
}