import thetatvImage from './assets/images/theta_tv.png';
import poweredByImage from './assets/images/powered_by.png';
import edgeNodeLogo from './assets/images/edge_node_logo.png';
import tvaLogo from './assets/images/tva_logo.png';
import logosSeparator from './assets/images/logos-separator.png';
import edgeNodeImage from './assets/images/edge_node.png';
import playIllustration from './assets/images/play_illustration.png';
import thetaIcon from './assets/images/theta_icon.png';
import React from "react";
import {PlayerAndTraffic} from "./components/PlayerAndTraffic";

function App() {

    return (
        <div className="app">
            <div className={"main-content"}>
                <img src={thetatvImage} alt="logo" className="logo"/>
                <div className={"slogan"}>A SHOWCASE OF THETA NETWORK WEB3 VIDEO INFRASTRUCTURE</div>

                <PlayerAndTraffic/>

                <div className={"powered-by-wrapper"}>
                    <img src={poweredByImage} alt="powered by" className="powered-by-image"/>
                    <div className={"powered-by-logos"}>
                        <a href={"https://www.thetavideoapi.com/"} rel="noreferrer" target="_blank"><img src={tvaLogo}
                                                                                                         alt="TVA logo"
                                                                                                         className="tva-logo"/></a>
                        <img src={logosSeparator} alt="" className="logos-separator"/>
                        <a href={"https://www.thetatoken.org/join"} rel="noreferrer" target="_blank"><img
                            src={edgeNodeLogo} alt="Edge Node logo" className="edge-node-logo"/></a>
                    </div>
                </div>

                <a href={"https://www.thetatoken.org/join"} rel="noreferrer" target="_blank">
                    <img src={edgeNodeImage} alt="edge node" className="edge-node-image"/>
                </a>
                <div className="header1 first">Download Theta Edge Node & earn TFUEL</div>
                <div className="text-wrap">Earn tokens by sharing your PC’s compute, storage, and bandwidth resources.
                </div>
                <div className={"download-for"}>Download for</div>
                <div className={"download-btns"}>
                    <a href={"https://api.thetatoken.org/downloads/edge-node/windows"} rel="noreferrer">
                        <div className={"download-btn windows"}>
                            <div className={"btn-icon windows"}/>
                            <div className={"btn-label"}>Windows</div>

                        </div>
                    </a>
                    <a href={"https://api.thetatoken.org/downloads/edge-node/macos"} rel="noreferrer">
                        <div className={"download-btn macos"}>
                            <div className={"btn-icon mac"}/>
                            <div className={"btn-label"}>Mac OS</div>
                        </div>
                    </a>
                    <a href={"https://docs.thetatoken.org/docs/theta-edge-node-run-with-docker"} rel="noreferrer"
                       target={"_blank"}>
                        <div className={"download-btn linux"}>
                            <div className={"btn-icon linux"}/>
                            <div className={"btn-label"}>Linux</div>
                        </div>
                    </a>
                </div>

                <a href={"https://www.thetavideoapi.com/"} rel="noreferrer" target="_blank">
                    <img src={playIllustration} alt="play illustration" className="play-illustration"/>
                </a>
                <div className="header1 second">Ready to leverage Web3 Video technology on your website or app?</div>
                <div className="text-wrap">Join leading media companies to accelerate user growth while driving
                    incremental revenues and reducing content delivery costs.
                </div>
                <a href={"https://www.thetavideoapi.com/"} rel="noreferrer" target="_blank">
                    <div className={"btn"}>Get started</div>
                </a>

                <a href={"https://www.thetatoken.org"} rel="noreferrer" target={"_blank"}>
                    <img src={thetaIcon} alt="theta icon" className="theta-icon"/>
                </a>
                <div className="header2">What is Theta Network</div>
                <div className={"theta-desc"}>Theta Network is a purpose-built Web3 blockchain infrastructure for video,
                    media and entertainment industry. Powered by a network of thousands of community run Theta edge
                    nodes, it enables leading media companies to drive incremental revenues, user engagement, and new
                    Web3 business models. <a href={"https://www.thetavideoapi.com/"} rel="noreferrer" target="_blank">Theta
                        Video API</a> is a turn-key decentralized video API for developers offering
                    significantly lower video transcoding, storage, and content delivery costs. <a
                        href={"https://www.thetadrop.com/"} rel="noreferrer" target="_blank">ThetaDrop NFT
                        marketplace</a> has partnered with global brands including Katy Perry, Samsung, Sony, ABS-CBN,
                    World
                    Poker Tour, Jukin Media, and more to disrupt the digital collectibles industry.
                </div>
                <a href={"https://www.thetatoken.org"} rel="noreferrer" target={"_blank"}>
                    <div className={"btn"}>Learn more</div>
                </a>
            </div>
            <div id={"g-tooltip"}/>
        </div>
    );
}

export default App;
