import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';


// Safeuly poll at an interval on functional components
export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval?.(tick, delay);
      return () => clearInterval?.(id);
    }
  }, [delay]);
}

export function useTimeout(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout?.(tick, delay);
      return () => clearTimeout?.(id);
    }
  }, [delay]);
}

export function sleep(delay) {
  return new Promise((res, rej) => {
    let id = setTimeout(() => {
      res();
    }, delay);
  })
}





export const withSplitName = f => (value, name, e, file) => {
  let part1 = name.split('-')[0];
  let part2 = name.split('-')[1];
  return f(part1, value, e, file, part1, part2)
}

export const withSuperSplitName = f => (value, name, e, file) => {
  let part1 = name.split('-')[0];
  let part2 = name.split('-')[1];
  let part3 = name.split('-')[2];
  return f(part1, value, e, file, part1, part2, part3)
}



