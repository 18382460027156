import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import cx from 'classnames';

import { Manager, Reference, Popper } from 'react-popper';


// ===========================
// COMPONENT
// ===========================
class TooltipComponent extends React.Component {
  state = {
    show: false
  }

  componentDidMount() {
    if(this.props.show)
      this.setState({ show: this.props.show })
  }
  componentDidUpdate(prev) {
    if(!_.isNil(this.props.show) && this.props.show !== this.state.show)
      this.setState({ show: this.props.show });
  }

  onMouseEnter = () => this.show()
  onMouseLeave = () => this.hide()

  show = () => {
    this.setState({ show: true })
  }
  hide = () => {
    this.setState({ show: false })
    this.props.onHide?.()
  }
  toggle = () => this.state.show
    ? this.hide()
    : this.show();

  render() {
    let { innerRef, children, tooltip, offset, enabled = true, showOverlay = false, placement = "top",
    referenceElement, positionFixed, overlayType = 'under-header', showArrow = true, alignLeft = false, ...props   } = this.props;
    let { show } = this.state;
    let modifiers = offset
      ? [{ name: 'offset', options: { offset }}]
      : undefined;
    return (
      <Manager>
        { children &&
        <Reference>
          { ({ref, ...innerProps}) => {
            if(_.isString(children))
              children = <span>{children}</span>;
            return React.cloneElement(
              children,
              { ref: node => { innerRef?.(node); ref?.(node) },
                ...( enabled
                  ? { onMouseEnter: this.onMouseEnter,
                      onMouseLeave: this.onMouseLeave, }
                  : {}),
                ...props, // Compatible with being in a dropdown target
                className: cx(children.props?.className, props.className),
              }, );
          }}
        </Reference>}
        { show &&
        ReactDOM.createPortal((
          <Popper
            placement={placement}
            modifiers={modifiers}
            eventsEnabled={true}
            referenceElement={referenceElement}
            strategy="fixed">
              {({ref, arrowProps, style, placement, ...popProps}) => (
                <div className={cx("g-tooltip", {alignLeft: alignLeft})} ref={ref} style={style}>
                  { tooltip }
                  { showArrow &&
                    <div className="tooltip-arrow"
                      ref={arrowProps.ref}
                      data-placement={placement}
                      style={arrowProps.style} />
                  }
                </div>
              )}
          </Popper>
        ), document.getElementById("g-tooltip"))}
      </Manager>);
  }
}
export const Tooltip = React.forwardRef((props, forwardRef) => <TooltipComponent {...props} innerRef={forwardRef} />)
export default Tooltip;
