import React, {useRef} from 'react';
import {useVideoJS} from "./useVideoJS";
import Storage from "../utils/storage";
import Hls from 'hls.js';

// Monkey Patch XHRHttpRequest
(function () {
    var xhrProto = XMLHttpRequest.prototype,
        origOpen = xhrProto.open;

    xhrProto.open = function (method, url) {
        return origOpen.apply(this, arguments);
    };
})();


export const VideoPlayer = ({video, playbackUrl, onTrafficUpdate}) => {
    const playerRef = useRef(null);
    const isLive = video?.type?.toLowerCase() === "live";
    const liveParams = isLive ? {
        remainingTimeDisplay: false,
        progressControl: false,
        playToggle: false
    } : {};

    const onThetaReady = (theta) => {
        if (theta) {
            theta.addEventListener(window.Theta.Events.TRAFFIC, function (data) {
                onTrafficUpdate(data);
            });
        } else {
            console.log("onThetaReady(): Error: theta does not exist");
        }
    };

    let bufferStalledErrorCount = 0;
    const {Video} = useVideoJS(
        {
            controls: true,
            autoplay: true,
            muted: true,
            loop: true,
            playsinline: true,
            techOrder: ["theta_hlsjs", "html5"],
            sources: [{
                src: playbackUrl,
                type: "application/vnd.apple.mpegurl",
            }],
            controlBar: liveParams,
            theta_hlsjs: {
                videoId: video.tva_stream_id,
                userId: Storage.getUserId(),
                walletUrl: "wss://api-wallet-service.thetatoken.org/theta/ws",
                onWalletAccessToken: async () => null,
                onThetaReady: onThetaReady,
                thetaOpts: {
                    allowRangeRequests: true,
                }
            },
            video_id: video.tva_stream_id,
            partner_id: null,
        },
        '',
        playerRef
    );

    return (
        <Video muted loop/>
    );
}