import {parseInt} from "lodash";

// export const formatBytes = (bytes, decimals = 2) => {
//   if (bytes === null || bytes === 'undefined' || isNaN(bytes)) return null;
//   if(bytes === 0)
//     return '0';
//   let k = 1024;
//   const resultMb = parseFloat((bytes / Math.pow(k, 2)).toFixed(decimals));
//   if (resultMb > 999999) return compactFormatter().format(resultMb)
//   else return numberFormatter().format(resultMb)
// }

export const formatBytes = (bytes, decimals = 1) => {
  if(bytes == 0)
    return '0 Bytes';
  let k = 1024;
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let i = Math.floor(Math.log(bytes) / Math.log(k));
  return {amount: parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)), unit: sizes[i]};
}

export const compactFormatter = () => Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 2 });
export const numberFormatter = () => Intl.NumberFormat('en');
