const Candidates = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','m','n','p','q','r','s','t','u','v','w','x','y','z'];

export function generate(prefix, length) {
    let string = prefix;
    for (let i = 0; i < length; i++) {
        string += Candidates[getRandomInt(Candidates.length)];
    }
    return string;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}
