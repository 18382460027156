export const sendBytesMetrics = async (videoId, bytesTemp) => {
    try {
        await fetch('https://api.thetavideoapi.com/metrics/offload', {
            method: 'POST',
            body: JSON.stringify({
                video_id: videoId,
                ...bytesTemp
            }),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    } catch (e) {
        console.log('Error: ', e)
    }
}

export const fetchVideo = async () => {
    const video = await fetch('https://sheet2api.com/v1/RSBMaYwSHCof/thetatv-relaunch-stream');
    return video.json();
}

export const fetchMetrics = async (videoId, showCdn) => {
    const cdnParam = showCdn ? '?cdn=true' : '';
    const metrics = await fetch(`https://api.thetavideoapi.com/metrics/offload${cdnParam}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return metrics.json();
}