import React, {useEffect, useRef, useState} from "react";
import HlsJs from "hls.js";
import videojs from "video.js";
import {VideoPlayer} from "./VideoPlayer";
import {PlayerOverlay} from "./PlayerOverlay";
import _ from "lodash";
import {Loading} from "./Loading";
import {fetchVideo, sendBytesMetrics} from "../utils/api";
import {useInterval} from "../utils/components";
import {mergeTrafficStats} from "../utils/traffic";
// Theta Plugins
const ThetaHlsPlugin = require("../theta/theta-hls-plugin.cjs.js").default;
const ThetaVideoJSPlugin = require("../theta/videojs-theta-plugin.cjs.js").default;

export const PlayerWrapper = ({onTrafficUpdate, onVideo}) => {

    const [video, setVideo] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    const [stats, setStats] = useState({})
    const [bytesTemp, setBytesTemp] = useState({cdn: 0, p2p_inbound: 0, p2p_outbound: 0})
    const [latestTrafficItem, setLatestTrafficItem] = useState(null)

    const statsRef = useRef({})
    statsRef.current = stats;
    const bytesTempRef = useRef({})
    bytesTempRef.current = bytesTemp;

    const latestTrafficItemRef = useRef({})
    latestTrafficItemRef.current = latestTrafficItem;

    const showOverlay = video?.show_overlay?.toLowerCase() === "true"


    useEffect(() => {
        loadVideo()
    }, []);

    useEffect(() => {
        ThetaHlsPlugin.registerPlugin(window.Theta, HlsJs);
        ThetaVideoJSPlugin.registerPlugin(window.Theta, HlsJs, videojs);
    }, []);

    useEffect(() => {
        if (!_.isEmpty(video)) onVideo(video)
    }, [video]);

    useEffect(() => {
        onTrafficUpdate(stats)
    }, [stats]);

    useInterval(() => {
        loadVideo()
    }, 60000)

    useInterval(() => {
        (async () => {
            if (_.isEmpty(video) && bytesTemp['cdn'] === 0 && bytesTemp['p2p_inbound'] === 0 && bytesTemp['p2p_outbound'] === 0) return
            await sendBytesMetrics(video.tva_stream_id, bytesTemp)
            setBytesTemp({cdn: 0, p2p_inbound: 0, p2p_outbound: 0})
        })();
    }, 30000);

    useInterval(() => {
        setStats(mergeTrafficStats(statsRef.current, latestTrafficItemRef.current))
        setLatestTrafficItem(null)
    }, 1000)

    const loadVideo = async () => {
        if (error) setLoading(true)
        setError(null)
        const videoResponse = await fetchVideo();
        if (!_.isEmpty(videoResponse)) {
            setVideo(videoResponse[0])
        }
        setLoading(false)
    }

    const onTrafficEvent = (data) => {
        const type = data['type'];
        const size = data['stats']['size'];
        if (type !== 'cdn' && type !== 'p2p_inbound' && type !== 'p2p_outbound') return
        setLatestTrafficItem({type, size})
        setBytesTemp({...bytesTempRef.current, [type]: bytesTempRef.current[type] + size})
    }


    if (error) {
        return (
            <div className={'g-player-wrapper'}>
                <div className={"video-player-wrapper loading"}>
                    <div className={"error-wrapper"}>
                        <div className={"error-text"}>Error: {error}</div>
                        <div className={"try-again-btn"} onClick={loadVideo}>Try Again</div>
                    </div>
                </div>
            </div>)
    }

    if (loading) {
        return (
            <div className={'g-player-wrapper'}>
                <div className={"video-player-wrapper loading"}>
                    <Loading/>
                </div>
            </div>)
    }

    return (
        <div className={'g-player-wrapper'}>

            <div className={"video-player-wrapper"}>
                <VideoPlayer playbackUrl={video?.tva_stream_url}
                             video={video}
                             onTrafficUpdate={onTrafficEvent}/>

                {showOverlay &&
                    <PlayerOverlay video={video}/>}
            </div>

        </div>
    );
}
