import {toNumber} from './number';
import _ from "lodash";
import {generate} from "./id-generator";

export const USER_ID = 'USER_ID';

const Storage = {
  getItem: key => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(key)
    }
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value)
  },
  clearItem: (key, value) => localStorage.removeItem(key),
  getUserId: () => {
    let userId = Storage.getItem(USER_ID);
    if (_.isNil(userId)) {
      userId = generate('usr', 15)
      Storage.setUserId(userId)
    }
    return userId;
  },
  setUserId: (value) => Storage.setItem(USER_ID, value),


}
export default Storage;