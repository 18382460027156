import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const COLOR_RED = '#ff3056';
const COLOR_GREEN = '#00c99a';
const COLOR_PURPLE = '#9440FF';


const graphData = {
    labels: Array.from(Array(30).keys()),
    datasets: [
        {
            fill: false,
            label: "Inbound P2P traffic",
            borderColor: COLOR_PURPLE,
            backgroundColor: COLOR_PURPLE,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
            data: [],
        },
        {
            fill: false,
            label: "Outbound P2P traffic",
            borderColor: COLOR_GREEN,
            backgroundColor: COLOR_GREEN,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
            data: [],
        },
        {
            fill: false,
            label: "CDN traffic",
            borderColor: COLOR_RED,
            backgroundColor: COLOR_RED,
            borderWidth: 2,
            cubicInterpolationMode: 'monotone',
            data: [],
        },
    ]
}

const graphOptions = {
    padding: 0,
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    // borderDash: [2, 2],
    plugins: {
        legend: {
            display: false
        }
    },
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        y: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                beginAtZero: true,
                display: false,
            },
        },
        x: {
            grid: {
                display: false
            },
            stacked: true,
            ticks: {
                beginAtZero: true,
                display: false,
            },
        }
    }
};


export const ThetaGraph = ({stats}) => {
    const graphRef = useRef(null);
    const baseChartData = graphData;

    const [labels, setLabels] = useState(Array.from(Array(30).keys()))
    const [cdnDataset, setCdnDataset] = useState([])
    const [p2pInboundDataset, setP2pInboundDataset] = useState([])
    const [p2pOutboundDataset, setP2pOutboundDataset] = useState([])
    const noData = _.isEmpty(cdnDataset) && _.isEmpty(p2pInboundDataset) && _.isEmpty(p2pOutboundDataset)

    const chartData = {
        ...baseChartData,
        labels,
        datasets: [
            {
                ...baseChartData.datasets[0],
                data: p2pInboundDataset,
            },
            {
                ...baseChartData.datasets[1],
                data: p2pOutboundDataset,
            },
            {
                ...baseChartData.datasets[2],
                data: cdnDataset,
            }]
    }

    useEffect(() => {
        if (_.isEmpty(stats))
            return;

        const cdnData = stats.chartData?.cdn;
        if (_.find(cdnData, (item) => item.y > 0)) {
            setCdnDataset(cdnData);
        } else {
            setCdnDataset([])
        }

        const p2pInboundData = stats.chartData?.p2p_inbound;
        if (_.find(p2pInboundData, (item) => item.y > 0)) {
            setP2pInboundDataset(p2pInboundData);
        } else {
            setP2pInboundDataset([])
        }

        const p2pOutboundData = stats.chartData?.p2p_outbound;
        if (_.find(p2pOutboundData, (item) => item.y > 0)) {
            setP2pOutboundDataset(p2pOutboundData);
        } else {
            setP2pOutboundDataset([])
        }

        setLabels(stats.chartLabels)

    }, [stats]);

    return (
        <div className={cx("g-theta-graph")}>
            <div className={"chart-wrap"}>
                <Line ref={graphRef} options={graphOptions} data={chartData}/>
                {noData && <div className={"chart-empty"} />}
            </div>
        </div>
    );
}

export default ThetaGraph;