import _ from "lodash";

export const STATS_TIMEFRAME = 30;

export const mergeTrafficStats = (currentStats, item) => {
    let {series = []} = currentStats;
    let timestamp = Math.floor(Date.now() / 1000);

    if (_.isEmpty(series)) {
        for (let ts = timestamp; ts >= timestamp - STATS_TIMEFRAME; ts--) {
            series.push({type: 'cdn', size: 0, timestamp: ts})
            series.push({type: 'p2p_inbound', size: 0, timestamp: ts})
            series.push({type: 'p2p_outbound', size: 0, timestamp: ts})
        }
    }

    series.push({type: 'cdn', size: item?.type === 'cdn' ? item.size : 0, timestamp: timestamp})
    series.push({type: 'p2p_inbound', size: item?.type === 'p2p_inbound' ? item.size : 0, timestamp: timestamp})
    series.push({type: 'p2p_outbound', size: item?.type === 'p2p_outbound' ? item.size : 0, timestamp: timestamp})

    const filteredSeries = _.filter(series, s => (timestamp - s.timestamp) < STATS_TIMEFRAME)
    const orderedSeries = _.orderBy(filteredSeries, 'timestamp', 'asc')

    let seriesType = _.groupBy(orderedSeries, 'type');

    let chartLabels = _.map(_.filter(orderedSeries, {type: 'cdn'}), 'timestamp');

    let chartData = _.mapValues(seriesType, s => _.map(s, (v, i) => {
        return Object.assign({x: v.timestamp, y: v.size})
    }))

    return {series: orderedSeries, chartLabels, chartData}
}

