import {PlayerWrapper} from "./PlayerWrapper";
import {TrafficBar} from "./TrafficBar";
import React, {useState} from "react";

export const PlayerAndTraffic = ({}) => {
    const [traffic, setTraffic] = useState({})
    const [video, setVideo] = useState(null)
    const onTrafficUpdate = (stats) => {
        setTraffic(stats)
    }

    const onVideo = (video) => {
        setVideo(video)
    }

    return (
        <div className={"player-and-traffic"}>
            <PlayerWrapper onTrafficUpdate={onTrafficUpdate} onVideo={onVideo}/>
            <TrafficBar traffic={traffic} video={video}/>
        </div>
    )
}
