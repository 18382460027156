import TrafficGraph from "./TrafficGraph";
import React, {useEffect, useState} from "react";
import {useInterval} from "../utils/components";
import {fetchMetrics} from "../utils/api";
import {compactFormatter, formatBytes} from "../utils/number";
import _ from "lodash";
import cx from "classnames";
import Tooltip from "./Tooltip";
import {iphone} from "../utils/browser";

export const TrafficBar = ({traffic, video}) => {

    const [globalTraffic, setGlobalTraffic] = useState({})

    const showCdn = video?.show_cdn?.toLowerCase() === "true"
    const totalTraffic = globalTraffic?.cdn + globalTraffic?.p2p_inbound;
    const offloadPercent = (_.isEmpty(globalTraffic) || globalTraffic.cdn === 0) ? null : Math.round((globalTraffic.p2p_inbound / (totalTraffic) * 100))
    const p2pInbound = formatBytes(globalTraffic?.p2p_inbound)
    const p2pOutbound = formatBytes(globalTraffic?.p2p_outbound)
    const cdn = formatBytes(globalTraffic?.cdn)

    useEffect(() => {
        fetchGlobalTraffic()
    }, [video]);

    useInterval(() => {
        fetchGlobalTraffic()
    }, 30000);

    const fetchGlobalTraffic = async () => {
        if (_.isEmpty(video)) return
        try {
            const trafficResponse = await fetchMetrics(video?.tva_stream_id, showCdn)
            setGlobalTraffic(trafficResponse.body)
        } catch (e) {
            console.log(e)
        }
    }

    return (<div className={"g-traffic-bar"}>

        {!iphone() && <div className={cx("traffic-graph-wrapper", {short: showCdn})}>
            <TrafficGraph stats={traffic}/>
        </div>}

        <div className={cx("traffic-stats", {long: showCdn})}>
            <div className={"traffic-column"}>
                <Tooltip tooltip={"Total P2P data contributed to Theta Network"}>
                    <div>
                        <div className={"label"}>Total<br/>To Peers <span className={"info-icon"} /></div>
                        <div className={"value green"}>{globalTraffic?.p2p_inbound
                            ? <>{p2pInbound.amount}<span className={"unit"}>{p2pInbound.unit}</span></>
                            : <>-</>}
                        </div>
                    </div>
                </Tooltip>
            </div>
            <div className={"traffic-column"}>
                <Tooltip tooltip={"Total P2P data shared by others from Theta Network"}>
                    <div>
                        <div className={"label"}>Total<br/> From Peers <span className={"info-icon"} /></div>
                        <div className={"value purple"}>{globalTraffic?.p2p_outbound
                            ? <>{p2pOutbound.amount}<span className={"unit"}>{p2pOutbound.unit}</span></>
                            : <>-</>}
                        </div>
                    </div>
                </Tooltip>
            </div>
            {showCdn && <>
                <div className={"traffic-column"}>
                    <div className={"label"}>Total<br/>From CDN</div>
                    <div className={"value red"}>{globalTraffic?.cdn
                        ? <>{cdn.amount}<span className={"unit"}>{cdn.unit}</span></>
                        : <>-</>}
                    </div>
                </div>
                <div className={"traffic-column"}>
                    <div className={"label"}>Offload</div>
                    <div className={"value"}>{offloadPercent ? `${offloadPercent}%` : "-"}</div>
                </div>
            </>
            }
        </div>
    </div>)
}